import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cp-rounded-button",
	templateUrl: "./rounded-button.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundedButtonComponent {
	@Input() label?: string;
	@Input() type: "submit" | "button" = "button";
	@Input() icon?: string;
	@Input() isIconFilled = false;
	@Input() isFullWidth = true;
	@Input() disabled = false;
	@Input() isReversed = false;
	@Input() readOnly = false;

	@Output() readonly clicked = new EventEmitter<void>();
}
