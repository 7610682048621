import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { RoundedButtonComponent } from "./rounded-button.component";

@NgModule({
	imports: [CommonModule, MatIconModule, MatRippleModule, EthIconComponent],
	declarations: [RoundedButtonComponent],
	exports: [RoundedButtonComponent],
})
export class RoundedButtonModule {}
