import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { FormControlDirective, FormControlName, NgControl } from "@angular/forms";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { NoopValueAccessorDirective } from "./noop-value-accessor/noop-value-accessor.directive";

@Component({
	selector: "eth-base-control-input",
	standalone: true,
	imports: [MatFormFieldModule],
	template: "",
	hostDirectives: [NoopValueAccessorDirective],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: "dynamic",
			},
		},
	],
})
export class EthBaseControlInputComponent {
	@Input() label?: string;
	@Input() name?: string;
	@Input() placeholder?: string;
	@Input() fill: "solid" | "translucid" | "empty" | "dashed" = "translucid";

	get placeholderOrLabel(): string {
		return this.placeholder ?? this.label ?? "";
	}

	ngControl = this.injectNgControl();

	private injectNgControl(): FormControlDirective | FormControlName {
		const ngControl = inject(NgControl, { self: true, optional: true });
		if (!ngControl) throw new Error("...");
		if (ngControl instanceof FormControlDirective || ngControl instanceof FormControlName) {
			return ngControl;
		}
		throw new Error("...");
	}
}
