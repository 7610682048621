import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { APP_CONFIG } from "@e-tenant-hub/shared/config";
import { Observable, tap } from "rxjs";

export interface RequestOptions {
	headers?: HttpHeaders;
	observe?: "body";
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: "json";
	withCredentials?: boolean;
	body?: unknown;
}

export interface FileProgress {
	percentage: number;
	totalFileSize: number;
	uploadedFileSize: number;
}

@Injectable()
export class RentalsHttpClient {
	private readonly appConfig = inject(APP_CONFIG);
	private httpClient = inject(HttpClient);

	/**
	 * HTTP GET request
	 * @param endPoint end point of the patient api
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	get<T>(endPoint: string, options?: RequestOptions): Observable<T> {
		return this.httpClient.get<T>(this.appConfig.apiRoot + endPoint, options);
	}

	/**
	 * HTTP POST request
	 * @param endPoint end point of the patient api
	 * @param params body of request
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	post<T>(endPoint: string, params: object, options?: RequestOptions): Observable<T> {
		return this.httpClient.post<T>(this.appConfig.apiRoot + endPoint, params, options);
	}

	/**
	 * HTTP PUT request
	 * @param endPoint end point of the patient api
	 * @param params body of request
	 * @param options options request (ex: header,body, params, etc..)
	 * @returns {Observable<T>}
	 */
	put<T>(endPoint: string, params: object | null, options?: RequestOptions): Observable<T> {
		return this.httpClient.put<T>(this.appConfig.apiRoot + endPoint, params, options);
	}

	/**
	 * HTTP DELETE request
	 * @param endPoint
	 * @param options
	 * @returns {Observable<T>}
	 */
	delete<T>(endPoint: string, options?: RequestOptions): Observable<T> {
		return this.httpClient.delete<T>(this.appConfig.apiRoot + endPoint, options);
	}

	downloadFile(endPoint: string): Observable<Blob> {
		return this.httpClient.get<Blob>(this.appConfig.apiRoot + endPoint, {
			responseType: "blob" as "json",
		});
	}

	downloadLocalFile(endPoint: string): Observable<Blob> {
		return this.httpClient.get<Blob>(endPoint, {
			responseType: "blob" as "json",
		});
	}

	uploadFile<T>(endPoint: string, body: object, callback: (n: FileProgress) => any): Observable<HttpEvent<T>> {
		const url = this.appConfig.apiRoot + endPoint;

		return this.httpClient
			.post<T>(url, body, {
				reportProgress: true,
				observe: "events",
			})
			.pipe(
				tap((event: HttpEvent<T>) => {
					if (event.type === HttpEventType.UploadProgress) {
						const fileProgress = {
							percentage: event.total ? Math.round((100 * event.loaded) / event.total) : undefined,
							totalFileSize: event.total,
							uploadedFileSize: event.loaded,
						} as FileProgress;
						callback(fileProgress);
					}
				})
			);
	}
}
