import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { EthIconComponent } from "@e-tenant-hub/shared/ui/icon";
import { MaskitoDirective } from "@maskito/angular";
import { CommonAutoCompleteInputComponent } from "./common-auto-complete-input.component";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatInputModule,
		EthIconComponent,
		MaskitoDirective,
	],
	declarations: [CommonAutoCompleteInputComponent],
	exports: [CommonAutoCompleteInputComponent],
})
export class CommonAutoCompleteInputModule {}
